import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import { Observable, finalize, map } from 'rxjs';
import { LoadingService } from './loading.service';
import {
  CreateAdditionalJointProfile,
  CreateAdditionalJointProfileData,
  CreateCustomerResponse,
  CreatedJointAccountResponse,
  CreateJointEmployment,
  CreateJointKinResponse,
  GetJointAccountData,
  ICreateCustomer,
  IGetJointAccountInfoResponse,
  IJointMeansOfIdentityForm,
  JointLocationData,
  JointLocationResponse,
} from '@core/models/admin/account/joint';
import { GetJointAccountResponse } from '@core/models/admin/account/joint';
import { URL_KEYS } from '@core/constants/url-keys.constants';

@Injectable({
  providedIn: 'root',
})
export class JointRegistrationService {
  http = inject(HttpClient);
  private loadingService = inject(LoadingService);
  loadingState = {
    createJointAccount: 'create-joint-account-main',
    addJointAccountUser: 'joint-account-addition',
    getJointAccountInfo: 'get-joint-account-info',
    addMeansOfIdentity: 'add-means-of-identity-kyc',
  };

  ADMIN_BASE_URL = `${environment.ADMIN_BASE_URL}/v1`;
  KYC_BASE_URL = `${environment.ADMIN_BASE_URL}/v1/account/joint-individual-account`;

  constructor() {}

  createMainAccount(
    formData: ICreateCustomer
  ): Observable<CreatedJointAccountResponse> {
    this.loadingService.setLoading(this.loadingState.createJointAccount, true);

    formData.marital_status = formData.marital_status.toLowerCase();
    return this.http
      .post<CreatedJointAccountResponse>(
        `${this.ADMIN_BASE_URL}/account/create-joint-account`,
        formData
      )
      .pipe(
        finalize(() =>
          this.loadingService.setLoading(
            this.loadingState.createJointAccount,
            false
          )
        )
      );
  }

  addJointUser(formData: ICreateCustomer): Observable<CreateCustomerResponse> {
    this.loadingService.setLoading(this.loadingState.createJointAccount, true);

    formData.marital_status = formData.marital_status.toLowerCase();
    return this.http
      .post<CreateCustomerResponse>(
        `${this.ADMIN_BASE_URL}/account/joint-account-addition`,
        formData
      )
      .pipe(
        finalize(() =>
          this.loadingService.setLoading(
            this.loadingState.createJointAccount,
            false
          )
        )
      );
  }

  addJointUserProfile(
    formData: ICreateCustomer
  ): Observable<CreateAdditionalJointProfileData> {
    this.loadingService.setLoading(this.loadingState.createJointAccount, true);

    formData.marital_status = formData.marital_status.toLowerCase();
    return this.http
      .post<CreateAdditionalJointProfile>(
        `${this.ADMIN_BASE_URL}/account/joint-account-addition`,
        formData
      )
      .pipe(
        map((res) => res.data),
        finalize(() =>
          this.loadingService.setLoading(
            this.loadingState.createJointAccount,
            false
          )
        )
      );
  }

  getJointAccountInfo(
    accountId: string
  ): Observable<IGetJointAccountInfoResponse> {
    this.loadingService.setLoading(this.loadingState.getJointAccountInfo, true);

    return this.http
      .get<IGetJointAccountInfoResponse>(
        `${this.ADMIN_BASE_URL}/account/get-joint-account-kyc?account_id=${accountId}`
      )
      .pipe(
        finalize(() =>
          this.loadingService.setLoading(
            this.loadingState.getJointAccountInfo,
            false
          )
        )
      );
  }

  updateCustomerProfile(payload: {
    user_identifier: string;
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    phone_number_1?: string;
    phone_number_2?: string;
    annual_income?: string;
    source_of_fund?: string;
    bvn?: string;
    dob?: string;
    state_of_origin?: string;
    nationality?: string;
    marital_status?: string;
    gender?: string;
    profile_id: string;
  }) {
    const uniqueStateUrl = URL_KEYS.JOINT.UPDATE_JOINT_ACCOUNT_PROFILE;
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(`${this.ADMIN_BASE_URL}/admin/update-profile`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addCustomerLocation(formData: any): Observable<JointLocationData> {
    const uniqueStateUrl = URL_KEYS.JOINT.CREATE_KYC_LOCATION;
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<JointLocationResponse>(
        `${this.ADMIN_BASE_URL}/kyc/create-joint-account-location-kyc`,
        formData
      )
      .pipe(
        map((res: JointLocationResponse) => res.data),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateLocationDetails(payload: {
    id: string;
    country: string;
    state: string;
    city: string;
    address: string;
    citizenship_status: string;
    second_country: string;
    residency_status: string;
    residence_permit: string;
    permit_issue_date: string;
    permit_expiry_date: string;
  }): Observable<unknown> {
    const uniqueStateUrl = URL_KEYS.JOINT.UPDATE_KYC_LOCATION;
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(
        `${this.ADMIN_BASE_URL}/kyc/update-joint-account-location-kyc`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addMeansOfId(formData: IJointMeansOfIdentityForm): Observable<unknown> {
    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/add-joint-account-identities`, formData)
      .pipe(
        finalize(() =>
          this.loadingService.setLoading(
            this.loadingState.addMeansOfIdentity,
            false
          )
        )
      );
  }
  updateMeansOfID(payload: IJointMeansOfIdentityForm): Observable<unknown> {
    const uniqueStateUrl = 'update-joint-account-identity-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(
        `${this.ADMIN_BASE_URL}/kyc/update-joint-account-identity-kyc`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addEmploymentDetails(payload: {
    country: string;
    state: string;
    city: string;
    status: string;
    nature_of_business: string;
    name: string;
    address: string;
    phone_number: string;
    joint_account_id: string;
    account_id: string;
  }): Observable<string> {
    const uniqueStateUrl = URL_KEYS.JOINT.ADD_JOINT_ACCOUNT_EMPLOYMENT_DETAILS;
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<CreateJointEmployment>(
        `${this.ADMIN_BASE_URL}/kyc/add-joint-account-employment-details`,
        payload
      )
      .pipe(
        map((res: CreateJointEmployment) => res.data.id),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateEmploymentDetails(payload: {
    id: string;
    status: string;
    name: string;
    nature_of_business: string;
    country: string;
    state: string;
    city: string;
    address: string;
    phone_number: string;
  }): Observable<unknown> {
    const uniqueStateUrl =
      URL_KEYS.JOINT.UPDATE_JOINT_ACCOUNT_EMPLOYMENT_DETAILS;
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(
        `${this.ADMIN_BASE_URL}/kyc/update-joint-account-employment-details`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addNextOfKin(formData: {
    title: string;
    gender: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    relationship_with_nok: string;
    dob: string;
    country: string;
    state: string;
    city: string;
    address: string;
    joint_account_id: string;
    account_id: string;
  }): Observable<string> {
    const uniqueStateUrl = URL_KEYS.JOINT.ADD_JOINT_ACCOUNT_NEXT_OF_KIN_KYC;
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<CreateJointKinResponse>(
        `${this.ADMIN_BASE_URL}/kyc/add-joint-account-next-of-kin-kyc`,
        formData
      )
      .pipe(
        map((res) => res.data.id),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateNextOfKin(formData: {
    title: string;
    gender: string;
    first_name: string;
    last_name: string;
    email: string;
    relationship_with_nok: string;
    dob: string;
    phone_number: string;
    country: string;
    state: string;
    city: string;
    address: string;
    id: string;
  }): Observable<unknown> {
    const uniqueStateUrl = URL_KEYS.JOINT.UPDATE_JOINT_ACCOUNT_NEXT_OF_KIN_KYC;
    this.loadingService.setLoading(uniqueStateUrl, true);

    const payload = Object.assign(formData, {
      gender: formData.gender.toLowerCase(),
    });

    return this.http
      .post(
        `${this.ADMIN_BASE_URL}/kyc/update-joint-account-next-of-kin-kyc`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  // addBankDetails(formData: any): Observable<CreateBankDetailsResponse> {
  //   const uniqueStateUrl = 'add-banking-details-to-account';
  //   this.loadingService.setLoading(uniqueStateUrl, true);

  //   return this.http
  //     .post<CreateBankDetailsResponse>(
  //       `${this.ADMIN_BASE_URL}/account/add-banking-details-to-account`,
  //       formData
  //     )
  //     .pipe(
  //       finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
  //     );
  // }

  // updateBankDetails(payload: {
  //   id: string;
  //   bank_account_name: string;
  //   bank_account_number: string;
  //   bank_code: string;
  //   bank_branch: string;
  //   bank_sort_code: string;
  //   currency: string;
  // }): Observable<unknown> {
  //   const uniqueStateUrl = 'update-banking-details';
  //   this.loadingService.setLoading(uniqueStateUrl, true);

  //   return this.http
  //     .post(`${this.ADMIN_BASE_URL}/account/update-banking-details`, payload)
  //     .pipe(
  //       finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
  //     );
  // }

  // this moves to a different service
  documents() {}

  getJointKycInfo(accountId: string): Observable<GetJointAccountData> {
    this.loadingService.setLoading(URL_KEYS.JOINT.GET_JOINT_ACCOUNT_KYC, true);

    return this.http
      .get<GetJointAccountResponse>(
        `${this.ADMIN_BASE_URL}/account/get-joint-account-kyc?account_id=${accountId}`
      )
      .pipe(
        map((response: GetJointAccountResponse) => response.data),
        finalize(() =>
          this.loadingService.setLoading(
            URL_KEYS.JOINT.GET_JOINT_ACCOUNT_KYC,
            false
          )
        )
      );
  }
}

// export interface ICreateCustomer {
//   first_name: string;
//   last_name: string;
//   title: string;
//   email: string;
//   phone_number_1: string;
//   phone_number_2: string;
//   dob: Date;
//   state_of_origin: string;
//   marital_status: string;
//   nationality: string;
//   annual_income: string;
//   bvn: string;
//   account_id?: string;
// }
